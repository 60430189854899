import axios from "axios";
import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

const user = JSON.parse(
  sessionStorage.getItem(
    "oidc.user:https://identity.bpldh-dev.groot.id:web_app_enduser"
  )
);

export const organizationProfileSubmit = (params) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/profile-organization/submit`,
    params,
    { headers }
  );
};
export const getOrganizationProfile = (params) => {
  const userId = localStorage.getItem("userId");

  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/profile-organization/findByUserId/${userId}`,
    params,
    { headers }
  );
};
