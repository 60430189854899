import React, { useMemo, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash';

import { updateDraftData, updateCompletedStatus } from 'app/actions';
import {
  getGeneralProfile,
  submitGeneralProfile,
} from 'services/danaProgram/profilUmum';
import {
  getCityService,
  getDistrictService,
  getPostCodeService,
  getProvinceService,
  getWardService,
} from 'services/masterData/region';

import { ErrorToast, ToastContext } from 'components/atoms/Toast';
import { ConfirmationModal, HeaderLabel, StepProgress } from 'components';
import AlertBlankData from 'components/molecules/Alert';
import BreadCrumbs from 'components/molecules/Breadcrumbs';

import InformasiUmum from './InformasiUmum';
import LandasanHukumPendirianLembaga from './LandasanHukumPendirianLembaga';
import WilayahKerja from './WilayahKerja';

const ProfileUmum = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    showToast,
    toastMessage,
    toastDescription,
    showToastMessage,
    hideToastMessage,
  } = useContext(ToastContext);
  const [activeStep, setActiveStep] = useState(1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBackModal, setConfirmBackModal] = useState(false);
  const [confirmDraftModal, setConfirmDraftModal] = useState(false);
  const [generalProfileDraftData, setGeneralProfileDraftData] = useState('');
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get('readonly') === 'true';

  const draftDataOnRedux = useSelector(
    (state) => state?.draftDataGeneralProfile?.data || {}
  );

  const userId = localStorage.getItem(`userId`);

  // const userId = user?.profile?.UserId[1];

  const [temp, setTemp] = useState();

  const submitMutation = useMutation({
    mutationFn: submitGeneralProfile,
    onSuccess: (data) => {
      // if (data.data?.data?.data?.current_step === 3) {
      if (submitMutation.variables.status === 'SUBMITTED') {
        dispatch(
          updateCompletedStatus({ profil_umum: true }, () => {
            navigate('/penilaian-lemtara');
            showToastMessage(
              'Data Berhasil Disimpan!',
              'Data yang anda masukan telah berhasil disimpan.'
            );
          })
        );
      }
    },
    onError: (error) => {
      showToastMessage('Error', error?.response?.data?.message);
      setConfirmModal(false);
    },
  });

  const mutation = useMutation({
    mutationFn: async () => {
      const promise = await getGeneralProfile(userId);
      const arrayData = promise?.data?.data;
      setGeneralProfileDraftData(arrayData);
    },
  });

  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);

  const getRegion = useMutation(async (name) => {
    try {
      const provinceResponse = await getProvinceService();
      setProvinces(_mapProvince(provinceResponse?.data?.data));
      const citiesResponse = await getCityService();
      setCities(_mapCity(citiesResponse?.data?.data));
      const districtResponse = await getDistrictService();
      setDistricts(_mapDistrict(districtResponse?.data?.data));
      const wardResponse = await getWardService();
      setWards(_mapWard(wardResponse?.data?.data));
      const zipCodeResponse = await getPostCodeService();
      setZipCodes(_mapZipCode(zipCodeResponse?.data?.data));
    } catch (error) {
      throw error;
    }
  });

  const _mapProvince = (data) => {
    const transformedData = data.map((item, index) => ({
      label: item.namaProvinsi,
      value: item.id,
      country: item.namaNegara,
    }));
    return transformedData;
  };

  const _mapCity = (data) => {
    const transformedData = data.map((item, index) => ({
      label: item.namaKabupatenKota,
      value: item.id,
      province: item.namaProvinsi,
    }));

    return transformedData;
  };

  const _mapDistrict = (data) => {
    const transformedData = data.map((item, index) => ({
      label: item.namaKecamatan,
      value: item.id,
      city: item.namaKabupatenKota,
    }));

    return transformedData;
  };

  const _mapWard = (data) => {
    const transformedData = data.map((item, index) => ({
      label: item.namaKelurahan,
      value: item.id,
      district: item.namaKecamatan,
    }));

    return transformedData;
  };

  const _mapZipCode = (data) => {
    const transformedData = data.map((item, index) => ({
      label: item.kodePos,
      value: item.id,
      ward: item.namaKelurahan,
    }));
    return transformedData;
  };

  useEffect(() => {
    getRegion.mutate();
    mutation.mutate();
  }, [activeStep, userId !== '', userId]);

  const consumeAPI = (data, status) => {
    let params = null;
    if (status === 'DRAFTED') {
      if (activeStep === 1) {
        params = {
          user_id: userId,
          status: status,
          current_step: activeStep,
          general_informations: {
            lemtara_name: data?.lemtara_name ?? '',
            intervention: data?.intervention ?? [],
            email: data?.email ?? '',
            institution_type: data?.institution_type ?? '',
            phone: data?.phone,
            contact_person: data?.contact_person ?? '',
            gender_pic: data?.gender_pic?.value ?? '',
            directors: data?.directors ?? '',
            phone_pic: data?.phone_pic ?? '',
            email_pic: data?.email_pic ?? '',
            general_social_media: {
              instagram: data?.general_social_media?.instagram ?? '',
              website: data?.general_social_media?.website ?? '',
              youtube: data?.general_social_media?.youtube ?? '',
              twitter: data?.general_social_media?.twitter ?? '',
              facebook: data?.general_social_media?.facebook ?? '',
            },
          },
        };
      } else if (activeStep === 2) {
        params = {
          user_id: userId,
          status: status,
          current_step: activeStep,
          general_profile_document: {
            proof_document_of_nib: data?.document_nib,
            proof_document_of_deed: data?.proof_document,
            additional_document: data?.additional_foundations?.map(
              (val) => val?.additional_document
            ),
          },
          legal_foundations: {
            no_nib: data?.no_nib ?? '',
            document_nib: data?.document_nib?.id ?? '',
            no_deed: data?.no_deed ?? '',
            date_deed: data?.date_deed ?? '',
            notary_public: data?.notary_public ?? '',
            proof_document: data?.proof_document?.id ?? '',
            additional_foundations: data?.additional_foundations?.map(
              (val) => ({
                name: val?.name,
                desc: val?.desc,
                additional_document: val?.additional_document?.id,
              })
            ),
          },
        };
      } else {
        params = {
          user_id: userId,
          status: status,
          current_step: activeStep,
          general_profile_document: {
            branch_data_document: data?.document_branch,
          },
          working_areas: {
            province_id: data?.province_id?.value,
            city_id: data?.city_id?.value,
            district_id: data?.district_id?.value,
            village_id: data?.village_id?.value,
            zip_code: data?.zip_code?.value,
            address: data?.address,
            lattitude: '-6.9004667',
            longitude: '107.6341435,13.55z',
            length_branch: data?.length_branch,
            document_branch: data?.document_branch?.id,
            area_coverage: data?.area_coverage?.map((item, index) => ({
              province_id: item?.province_id?.value,
              city_id: item?.city_id?.value,
            })),
          },
        };
      }
    } else {
      params = {
        user_id: userId,
        status: status,
        current_step: activeStep,
        general_profile_document: {
          branch_data_document: draftDataOnRedux?.working_area?.document_branch,
          proof_document_of_nib:
            draftDataOnRedux?.legal_foundations?.document_nib,
          proof_document_of_deed:
            draftDataOnRedux?.legal_foundations?.proof_document,
          additional_document:
            draftDataOnRedux?.legal_foundations?.additional_foundations?.map(
              (val) => val?.additional_document
            ),
        },
        general_informations: {
          lemtara_name:
            draftDataOnRedux?.general_information?.lemtara_name ?? '',
          intervention:
            draftDataOnRedux?.general_information?.intervention ?? [],
          email: draftDataOnRedux?.general_information?.email ?? '',
          institution_type:
            draftDataOnRedux?.general_information?.institution_type ?? '',
          phone: draftDataOnRedux?.general_information?.phone,
          contact_person:
            draftDataOnRedux?.general_information?.contact_person ?? '',
          gender_pic:
            draftDataOnRedux?.general_information?.gender_pic?.value ?? '',
          directors: draftDataOnRedux?.general_information?.directors ?? '',
          phone_pic: draftDataOnRedux?.general_information?.phone_pic ?? '',
          email_pic: draftDataOnRedux?.general_information?.email_pic ?? '',
          general_social_media: {
            instagram:
              draftDataOnRedux?.general_information?.general_social_media
                ?.instagram ?? '',
            website:
              draftDataOnRedux?.general_information?.general_social_media
                ?.website ?? '',
            youtube:
              draftDataOnRedux?.general_information?.general_social_media
                ?.youtube ?? '',
            twitter:
              draftDataOnRedux?.general_information?.general_social_media
                ?.twitter ?? '',
            facebook:
              draftDataOnRedux?.general_information?.general_social_media
                ?.facebook ?? '',
          },
        },
        legal_foundations: {
          no_nib: draftDataOnRedux?.legal_foundations?.no_nib ?? '',
          document_nib:
            draftDataOnRedux?.legal_foundations?.document_nib?.id ?? '',
          no_deed: draftDataOnRedux?.legal_foundations?.no_deed ?? '',
          date_deed: draftDataOnRedux?.legal_foundations?.date_deed ?? '',
          notary_public:
            draftDataOnRedux?.legal_foundations?.notary_public ?? '',
          proof_document:
            draftDataOnRedux?.legal_foundations?.proof_document?.id ?? '',
          additional_foundations: [
            {
              name: draftDataOnRedux?.legal_foundations?.country?.value ?? '',
              desc: draftDataOnRedux?.legal_foundations?.country?.value ?? '',
              additional_document:
                draftDataOnRedux?.legal_foundations?.city?.value ?? '',
            },
          ],
        },
        working_areas: {
          province_id: draftDataOnRedux?.working_area?.province_id,
          city_id: draftDataOnRedux?.working_area?.city_id,
          district_id: draftDataOnRedux?.working_area?.district_id,
          village_id: draftDataOnRedux?.working_area?.village_id,
          zip_code: draftDataOnRedux?.working_area?.zip_code,
          address: draftDataOnRedux?.working_area?.address,
          lattitude: '-6.9004667',
          longitude: '107.6341435,13.55z',
          length_branch: draftDataOnRedux?.working_area?.length_branch,
          document_branch:
            draftDataOnRedux?.working_area?.document_branch?.id ?? '',
          area_coverage: draftDataOnRedux?.working_area?.area_coverage?.map(
            (item, index) => ({
              province_id: item?.province_id?.value,
              city_id: item?.city_id?.value,
            })
          ),
        },
      };
    }

    submitMutation.mutate(params);
  };

  const dispatchData = (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== null && value !== undefined && value !== ''
      )
    );

    if (activeStep === 1) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(
          generalProfileDraftData?.data?.general_information,
          filteredData
        )
      ) {
        const updatedGeneralInformation = {
          ...generalProfileDraftData?.data?.general_information,
          ...filteredData,
        };

        dispatch(
          updateDraftData({
            ...generalProfileDraftData?.data,
            general_information: updatedGeneralInformation,
          })
        );
      }
    } else if (activeStep === 2) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(generalProfileDraftData?.data?.legal_foundations, filteredData)
      ) {
        const updatedLegalFoundations = {
          ...generalProfileDraftData?.data?.legal_foundations,
          ...filteredData,
        };

        dispatch(
          updateDraftData({
            ...generalProfileDraftData?.data,
            legal_foundations: updatedLegalFoundations,
          })
        );
      }
    } else if (activeStep === 3) {
      if (
        Object.keys(filteredData).length > 0 &&
        !isEqual(generalProfileDraftData?.data?.working_area, filteredData)
      ) {
        const updatedWorkingArea = {
          ...generalProfileDraftData?.data?.working_area,
          ...filteredData,
        };

        dispatch(
          updateDraftData({
            ...generalProfileDraftData?.data,
            working_area: updatedWorkingArea,
          })
        );
      }
    }
  };

  const onDraftSubmit = (data) => {
    setTemp(data);
    dispatchData(data);
    setConfirmDraftModal(true);
  };

  const onBack = (data) => {
    if (isReadonly) {
      dispatchData(data);
      setActiveStep(activeStep - 1);
      if (activeStep === 1) {
        dispatchData(data);
        navigate(-1);
      }

      return;
    }
    setTemp(data);
    dispatchData(data);
    setConfirmBackModal(true);
  };

  const onSubmit = (data) => {
    if (isReadonly) {
      setActiveStep(activeStep + 1);
    } else {
      if (activeStep !== 3) {
        consumeAPI(data, 'DRAFTED');
      }
      setTemp(data);
      dispatchData(data);
      if (activeStep < stepProgress.length) {
        setActiveStep(activeStep + 1);
      } else {
        dispatchData(data);
        setConfirmModal(true);
      }
    }
  };

  const onSubmitConfirmModal = (data) => {
    consumeAPI(data, 'SUBMITTED');
  };

  const onSubmitConfirmBackModal = () => {
    consumeAPI(temp, 'DRAFTED');
    if (activeStep === 1) {
      navigate(-1);
      showToastMessage(
        'Data Berhasil Disimpan!',
        'Data yang anda masukan telah berhasil disimpan.'
      );
    } else {
      setActiveStep(activeStep - 1);
    }
    setConfirmBackModal(false);
  };

  const onSubmitConfirmDraftModal = () => {
    consumeAPI(temp, 'DRAFTED');
    navigate('/penilaian-lemtara');
    showToastMessage(
      'Data Berhasil Disimpan!',
      'Data yang anda masukan telah berhasil disimpan.'
    );
  };

  const breadCrumbs = [
    {
      label: 'Penilaian LEMTARA',
      path: '/penilaian-lemtara',
    },
    {
      label: 'Profil Umum',
      path: '/profil-umum',
    },
  ];

  const stepProgress = useMemo(() => [
    {
      label: 'Informasi Umum',
      step: 1,
    },
    {
      label: 'Landasan Hukum Pendirian Lembaga',
      step: 2,
    },
    {
      label: 'Wilayah Kerja',
      step: 3,
    },
  ]);

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onClose={setConfirmModal}
          onCancel={() => setConfirmBackModal(false)}
          onSubmit={onSubmitConfirmModal}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {createPortal(
        <ConfirmationModal
          open={confirmDraftModal}
          onClose={setConfirmDraftModal}
          onCancel={() => setConfirmBackModal(false)}
          onSubmit={() => onSubmitConfirmDraftModal()}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin menyimpan ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda menyimpan sebagai draft anda masih bisa melanjutkan.
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {createPortal(
        <ConfirmationModal
          open={confirmBackModal}
          onClose={setConfirmBackModal}
          onCancel={() => setConfirmBackModal(false)}
          onSubmit={() => onSubmitConfirmBackModal()}
          className="space-y-2 mb-4"
        >
          <div>
            <div className="text-lg font-[600]">
              Apakah anda yakin untuk kembali?
            </div>
            <div className="text-sm font-[400]">
              Setelah anda kembali semua data akan disimpan pada draft.
            </div>
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {showToast &&
        createPortal(
          <ErrorToast
            message={toastMessage}
            message_description={toastDescription}
            onClose={hideToastMessage}
          />,
          document.body,
          'toast-success-upload-file-requirement-proposal'
        )}
      <div className="w-full space-y-6 bg-white  rounded-3xl border p-10 mb-6">
        <BreadCrumbs routes={breadCrumbs} />

        <div className="flex">
          <HeaderLabel
            text="Profil Umum"
            description="Silahkan daftar menggunakan data yang Anda miliki."
          />
        </div>

        <div className="border-b" />

        <StepProgress stepProgressData={stepProgress} activeStep={activeStep} />

        <div className="w-full pt-4">
          <AlertBlankData
            title={`Lengkapi Data`}
            body={`Silahkan isi semua data di bawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
          />
        </div>
        {activeStep === 1 && (
          <InformasiUmum
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={generalProfileDraftData?.general_information}
          />
        )}
        {activeStep === 2 && (
          <LandasanHukumPendirianLembaga
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={generalProfileDraftData?.legal_foundation}
          />
        )}
        {activeStep === 3 && (
          <WilayahKerja
            onSubmit={onSubmit}
            onBack={onBack}
            onDraftSubmit={onDraftSubmit}
            draftData={generalProfileDraftData?.working_area}
            regionData={{
              province_ids: provinces,
              city_ids: cities,
              district_ids: districts,
              village_ids: wards,
              zip_codes: zipCodes,
            }}
          />
        )}
      </div>
    </>
  );
};

export default ProfileUmum;
