import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  "Content-Type": "application/json",
  "ngrok-skip-browser-warning": true,
};

export const submitExperienceProgramme = (params) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/experience-programme/submit`,
    params,
    {
      headers,
    }
  );
};

export const getExperienceProgramme = (params) => {
  const userId = localStorage.getItem("userId");

  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/experience-programme/findByUserId/${userId}`,
    params,
    { headers }
  );
};
