import React, { useMemo, useEffect, useState } from 'react';
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  InputFormUploadNew as InputFormUpload,
} from 'components';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import AtomSelect from 'components/atoms/Select';
import CardForm from 'components/molecules/CardForm';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiQuestionLine } from 'react-icons/ri';
import AtomDatePicker from 'components/atoms/Datepicker';
import { useMutation } from '@tanstack/react-query';
import { fileUpload, getFile } from 'services/danaProgram/fileService';
import { useSelector } from 'react-redux';
import {
  getCityService,
  getCountryService,
  getDistrictService,
  getPostCodeService,
  getProvinceService,
  getWardService,
} from 'services/masterData/region';
import { _mapMasterData } from 'helpers/mapMasterData';
import { getCollaborationFields } from 'services/danaProgram/masterDataService';

const Kemitraan = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  const masterData = useSelector((state) => state?.draftMasterData?.data || {});
  const regionStore = useSelector(
    (state) => state?.regionDraftData?.data || {}
  );
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);
  const [cooperations, setCooperations] = useState([]);

  const validationSchema = useMemo(() => {
    return yup.object({
      partners1_name: yup
        .string()
        .required()
        .nonNullable()
        .label('nama wajib diisi'),
      partners1_cooperation: yup
        .mixed()
        .required('bidang kerja sama wajib diisi'),
      partners1_start_date: yup.string().required('tanggal mulai wajib diisi'),
      partners1_end_date: yup.mixed().required('tanggal selesai wajib diisi'),
      partners1_nation: yup.mixed().required('negara wajib diisi'),
      partners1_province_id: yup.mixed().required('provinsi wajib diisi'),
      partners1_city_id: yup.mixed().required('kota wajib diisi'),
      partners1_district_id: yup.mixed().required('kecamatan wajib diisi'),
      partners1_village_id: yup.mixed().required('kelurahan wajib diisi'),
      partners1_zip_code: yup.mixed().required('kode pos wajib diisi'),
      partners1_address: yup.string().required('alamat wajib diisi'),
      partners2_name: yup
        .string()
        .required()
        .nonNullable()
        .label('nama wajib diisi'),
      partners2_cooperation: yup
        .mixed()
        .required('bidang kerja sama wajib diisi'),
      partners2_start_date: yup.string().required('tanggal mulai wajib diisi'),
      partners2_end_date: yup.mixed().required('tanggal selesai wajib diisi'),
      partners2_nation: yup.mixed().required('negara wajib diisi'),
      partners2_province_id: yup.mixed().required('provinsi wajib diisi'),
      partners2_city_id: yup.mixed().required('kota wajib diisi'),
      partners2_district_id: yup.mixed().required('kecamatan wajib diisi'),
      partners2_village_id: yup.mixed().required('kelurahan wajib diisi'),
      partners2_zip_code: yup.mixed().required('kode pos wajib diisi'),
      partners2_address: yup.string().required('alamat wajib diisi'),
    });
  });

  const getCollaboration = useMutation(async () => {
    try {
      const collabResponse = await getCollaborationFields();
      setCooperations(_mapMasterData(collabResponse?.data?.data));
    } catch (error) {
      throw error;
    }
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const getRegion2 = useMutation(async (name) => {
    try {
      const wardResponse = await getWardService();
      _mapWard(wardResponse?.data?.data);
    } catch (error) {
      throw error;
    }
  });

  const getRegion5 = useMutation(async (name) => {
    try {
      const zipCodeResponse = await getPostCodeService();
      _mapZipCode(zipCodeResponse?.data?.data);
    } catch (error) {
      throw error;
    }
  });
  const getRegion1 = useMutation(async (name) => {
    try {
      const citiesResponse = await getCityService();
      _mapCity(citiesResponse?.data?.data);
    } catch (error) {
      throw error;
    }
  });

  const getRegion4 = useMutation(async (name) => {
    try {
      const districtResponse = await getDistrictService();
      _mapDistrict(districtResponse?.data?.data);
    } catch (error) {
      throw error;
    }
  });

  const getRegion = useMutation(async (name) => {
    try {
      const countryResponse = await getCountryService();
      _mapCountry(countryResponse?.data?.data);
    } catch (error) {
      throw error;
    }
  });

  const getRegion3 = useMutation(async (name) => {
    try {
      const provinceResponse = await getProvinceService();
      _mapProvince(provinceResponse?.data?.data);
    } catch (error) {
      throw error;
    }
  });

  const _mapCountry = (data) => {
    const temp = data?.map((item, index) => ({
      label: item.namaNegara,
      value: item.id,
    }));
    setCountries(temp);
  };

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const { setValue, getValues, watch } = methods;

  function findDataByValue(name, data, targetValue) {
    const foundItem = data?.find((item) => item?.value === targetValue);
    return foundItem;
  }

  function filterDataByLabels(data, labelsToFilter) {
    let filteredData = data?.filter((item) =>
      labelsToFilter.includes(item.label)
    );
    return filteredData;
  }

  const _mapProvince = (data, name) => {
    const transformedData = data?.map((item, index) => ({
      label: item.namaProvinsi,
      value: item.id,
      country: item.namaNegara,
    }));
    setProvinces(transformedData);
  };

  const _mapCity = (data, name) => {
    const transformedData = data?.map((item, index) => ({
      label: item.namaKabupatenKota,
      value: item.id,
      province: item.namaProvinsi,
    }));
    setCities(transformedData);
  };

  const _mapDistrict = (data, name) => {
    const transformedData = data?.map((item, index) => ({
      label: item.namaKecamatan,
      value: item.id,
      city: item.namaKabupatenKota,
    }));
    setDistricts(transformedData);
  };

  const _mapWard = (data, name) => {
    const transformedData = data?.map((item, index) => ({
      label: item.namaKelurahan,
      value: item.id,
      district: item.namaKecamatan,
    }));
    setWards(transformedData);
  };

  const _mapZipCode = (data, name) => {
    const transformedData = data?.map((item, index) => ({
      label: item.kodePos,
      value: item.id,
      ward: item.namaKelurahan,
    }));
    setZipCodes(transformedData);
  };

  useEffect(() => {
    getCollaboration.mutate();
    getRegion.mutate();
    getRegion1.mutate();
    getRegion2.mutate();
    getRegion3.mutate();
    getRegion4.mutate();
    getRegion5.mutate();
  }, []);

  useEffect(() => {
    if (draftData) {
      const { partner, supporting_documents, ...restData } = draftData;
      Object.keys(restData).forEach((key) => {
        if (key === 'start_date' || key === 'end_date') {
          setValue(key, restData[key]);
        } else {
          setValue(key, restData[key]);
        }
      });
      if (
        partner?.length > 0 &&
        countries?.length > 0 &&
        provinces?.length > 0 &&
        cities?.length > 0 &&
        districts?.length > 0 &&
        wards?.length > 0 &&
        zipCodes?.length > 0
      ) {
        partner?.map((partnerItem, index) =>
          Object.keys(partnerItem).forEach((key) => {
            if (key === 'document') {
              getFileMutation.mutate({
                id: partnerItem?.document,
                key: `partners${index + 1}_url`,
              });
            } else if (key === 'cooperation') {
              let temp = filterDataByLabels(cooperations, partnerItem[key]);
              setValue(`partners${index + 1}_cooperation`, temp);
            } else if (key === 'nation') {
              let temp = findDataByValue(
                'country',
                countries,
                partnerItem.nation
              );
              setValue(`partners${index + 1}_nation`, temp);
            } else if (key === 'province_id') {
              let temp = findDataByValue(
                'province',
                provinces,
                partnerItem.province_id
              );
              setValue(`partners${index + 1}_province_id`, temp);
            } else if (key === 'city_id') {
              let temp = findDataByValue('city', cities, partnerItem.city_id);
              setValue(`partners${index + 1}_city_id`, temp);
            } else if (key === 'district_id') {
              let temp = findDataByValue(
                'district',
                districts,
                partnerItem.district_id
              );
              setValue(`partners${index + 1}_district_id`, temp);
            } else if (key === 'village_id') {
              let temp = findDataByValue('ward', wards, partnerItem.village_id);
              setValue(`partners${index + 1}_village_id`, temp);
            } else if (key === 'zip_code') {
              let temp = findDataByValue(
                'zip_code',
                zipCodes,
                partnerItem.zip_code
              );
              setValue(`partners${index + 1}_zip_code`, temp);
            } else {
              setValue(`partners${index + 1}_${key}`, partnerItem[key]);
            }
          })
        );
      }

      if (
        supporting_documents &&
        Array.isArray(supporting_documents) &&
        supporting_documents.length > 0
      ) {
        getFileMutation.mutate({
          id: supporting_documents[0]?.path,
          key: `supporting_documents.0.url`,
        });
        setValue(`supporting_documents.0.desc`, supporting_documents[0]?.desc);
      }

      methods.trigger();
    }
  }, [
    draftData,
    setValue,
    methods,
    countries,
    provinces,
    cities,
    districts,
    wards,
    zipCodes,
  ]);

  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);

      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: response.data.data.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);
        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: response.data.data.size,
        };
        setValue(key, temp);
      }
    } catch (error) {
      throw error;
    }
  });

  function findWordInString(inputString, targetWord) {
    if (inputString) {
      const wordPattern = new RegExp(`\\b${targetWord}\\b`, 'i');

      const match = inputString.match(wordPattern);
      return match ? match[0] : null;
    }
  }

  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get('readonly') === 'true';

  return (
    <>
      <FormProvider {...methods}>
        <CardForm label={'Kemitraan'}>
          <>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    'Berapa banyak mitra yang pernah bekerja sama dengan Lembaga/ institusi/ perusahaan Anda?'
                  }
                  controllerName={'partners_total'}
                  values={[
                    {
                      label: 'Belum Ada',
                      value: 'Belum Ada',
                    },
                    {
                      label: '1-5 Mitra',
                      value: '1-5 Mitra',
                    },
                    {
                      label: '6-10 Mitra',
                      value: '6-10 Mitra',
                    },
                    {
                      label: 'Lebih dari 10 Mitra',
                      value: 'Lebih dari 10 Mitra',
                    },
                  ]}
                  disabled={isReadonly}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    'Berapa banyak rekanan/mitra yang saat ini masih berlangsung kerjasamanya? (dari skala 1-10)'
                  }
                  controllerName={'level_anti_korupsi'}
                  values={[
                    {
                      label: 1,
                      value: 1,
                    },
                    {
                      label: 2,
                      value: 2,
                    },
                    {
                      label: 3,
                      value: 3,
                    },
                    {
                      label: 4,
                      value: 4,
                    },
                    {
                      label: 5,
                      value: 5,
                    },
                    {
                      label: 6,
                      value: 6,
                    },
                    {
                      label: 7,
                      value: 7,
                    },
                    {
                      label: 8,
                      value: 8,
                    },
                    {
                      label: 9,
                      value: 9,
                    },
                    {
                      label: 10,
                      value: 10,
                    },
                  ]}
                  disabled={isReadonly}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg text-[#026AA2] border-[#7CD4FD]">
                <RiQuestionLine />
                Silahkan lengkapi data kemitraan Anda
              </div>
            </div>
            <CardForm
              label={`Rekanan/ Mitra 1`}
              buttonCollapse={true}
              childerForm={true}
            >
              <>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`partners1_name`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md ${
                        errors.nama_lemtara
                          ? 'border-[#F04438]'
                          : 'border-green'
                      }`}
                      label={'Nama Rekanan LEMTARA'}
                      placeholder={'Nama Rekanan lemtara'}
                      required={true}
                      disabled={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners1_cooperation`}
                      options={cooperations}
                      className="basic-single mt-2"
                      label="bidang kerjasama"
                      required={true}
                      isMulti
                      disable={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <AtomDatePicker
                      controllerName={`partners1_start_date`}
                      label="tanggal mulai kerjasama"
                      required={true}
                      newDate={true}
                      asText={isReadonly}
                      showErrorLabel
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomDatePicker
                      controllerName={`partners1_end_date`}
                      label="tanggal selesai kerjasama"
                      required={true}
                      newDate={true}
                      asText={isReadonly}
                      showErrorLabel
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners1_nation`}
                      options={countries}
                      className="basic-single mt-2"
                      label="Negara"
                      required={true}
                      disable={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners1_province_id`}
                      options={provinces}
                      className="basic-single mt-2"
                      label="Provinsi"
                      required={true}
                      disable={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners1_city_id`}
                      options={cities}
                      className="basic-single mt-2"
                      label="Kota / Kabupaten"
                      required={true}
                      disable={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners1_district_id`}
                      options={districts}
                      className="basic-single mt-2"
                      label="Kecamatan"
                      required={true}
                      disable={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners1_village_id`}
                      options={wards}
                      className="basic-single mt-2"
                      label="Kelurahan"
                      required={true}
                      disable={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners1_zip_code`}
                      className={`basic-single mt-2 ${
                        errors.zip_code ? 'border-[#F04438]' : 'border-green'
                      }`}
                      options={zipCodes}
                      required={true}
                      label={'Kodepos'}
                      placeholder={'kode pos'}
                      disable={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`partners1_address`}
                      className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                      label={'Alamat Lengkap'}
                      placeholder={'Alamat Lengkap'}
                      textArea={true}
                      required={true}
                      disabled={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <InputFormUpload
                      controllerName={`partners1_url`}
                      label={
                        'Dokumen struktur organisasi Lembaga/ Instansi/ perusahaan'
                      }
                      uploadFile={mutation}
                      methods={methods}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
              </>
            </CardForm>
            <CardForm
              label={`Rekanan/ Mitra 2`}
              buttonCollapse={true}
              childerForm={true}
            >
              <>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`partners2_name`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md ${
                        errors.nama_lemtara
                          ? 'border-[#F04438]'
                          : 'border-green'
                      }`}
                      label={'Nama Rekanan LEMTARA'}
                      placeholder={'Nama Rekanan lemtara'}
                      required={true}
                      disabled={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners2_cooperation`}
                      options={cooperations}
                      className="basic-single mt-2"
                      label="bidang kerjasama"
                      required={true}
                      isMulti
                      disable={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <AtomDatePicker
                      controllerName={`partners2_start_date`}
                      label="tanggal mulai kerjasama"
                      required={true}
                      newDate={true}
                      asText={isReadonly}
                      showErrorLabel
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomDatePicker
                      controllerName={`partners2_end_date`}
                      label="tanggal selesai kerjasama"
                      required={true}
                      newDate={true}
                      asText={isReadonly}
                      showErrorLabel
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners2_nation`}
                      options={countries}
                      className="basic-single mt-2"
                      label="Negara"
                      required={true}
                      disable={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners2_province_id`}
                      options={provinces}
                      className="basic-single mt-2"
                      label="Provinsi"
                      required={true}
                      disable={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners2_city_id`}
                      options={cities}
                      className="basic-single mt-2"
                      label="Kota / Kabupaten"
                      required={true}
                      disable={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners2_district_id`}
                      options={districts}
                      className="basic-single mt-2"
                      label="Kecamatan"
                      required={true}
                      disable={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners2_village_id`}
                      options={wards}
                      className="basic-single mt-2"
                      label="Kelurahan"
                      required={true}
                      disable={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`partners2_zip_code`}
                      className={`basic-single mt-2 ${
                        errors.zip_code ? 'border-[#F04438]' : 'border-green'
                      }`}
                      options={zipCodes}
                      required={true}
                      label={'Kodepos'}
                      placeholder={'kode pos'}
                      disable={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`partners2_address`}
                      className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                      label={'Alamat Lengkap'}
                      placeholder={'Alamat Lengkap'}
                      textArea={true}
                      required={true}
                      disabled={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <InputFormUpload
                      controllerName={`partners2_url`}
                      label={
                        'Dokumen struktur organisasi Lembaga/ Instansi/ perusahaan'
                      }
                      uploadFile={mutation}
                      methods={methods}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
              </>
            </CardForm>
          </>
        </CardForm>
        <CardForm label={'Lampirkan Dokumen Pendukung'}>
          <div className="flex gap-4">
            <div className="flex-1 pt-2">
              <InputFormUpload
                controllerName={`supporting_documents.${0}.url`}
                label={'dokumen strategi lembaga kemitraan'}
                uploadFile={mutation}
                methods={methods}
                disabled={isReadonly}
              />
            </div>
            <div className="flex-1 pt-2">
              <InputForm
                controllerName={`supporting_documents.${0}.desc`}
                className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                label={'Uraian singkat terkait isi dokumen'}
                placeholder={'Uraian singkat'}
                textArea={true}
                required={true}
                disabled={isReadonly}
              />
            </div>
          </div>
        </CardForm>

        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          hideDraft={isReadonly}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            const values = methods.getValues();
            console.log(errors, values);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default Kemitraan;
