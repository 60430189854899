import { Button, InputForm } from "components";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";

const OtherAsset = ({
  nestIndex = 0,
  sumOtherAssetTotal = () => { },
  convertMoney = () => { },
  setValue = () => { },
  ...props
}) => {
  const search = new URLSearchParams(window.location.search);
  const isReadonly = search.get("readonly") === "true";
  const { watch, getValue, getValues, control } = useFormContext();
  const {
    fields: financialReportOtherAssetFields,
    append: appendFinancialReportOtherAsset,
    remove: removeFinancialReportOtherAsset,
  } = useFieldArray({
    control,
    name: `financial_report.${nestIndex}.other_asset`,
  });
  return (
    <>
      {financialReportOtherAssetFields.length > 0
        ? financialReportOtherAssetFields.map((data, index) => (
          <div className="flex gap-4 items-end  pt-2" key={data.id}>
            <div className="flex-1 pt-2">
              <InputForm
                // controllerName={`otherAsset.${index}.name`}
                controllerName={`financial_report.${nestIndex}.other_asset.${index}.name`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={"Nama Aktiva Lainnya"}
                placeholder={"Aktiva"}
                disabled={isReadonly}
              />
            </div>
            <div className="flex-1 pt-2">
              <InputForm
                // controllerName={`otherAsset.${index}.value`}
                controllerName={`financial_report.${nestIndex}.other_asset.${index}.value`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={"Nilai"}
                placeholder={"0"}
                // defaultValue={
                //   otherAssetCustom[index]?.value
                // }
                key={data.id}
                showPrefix={true}
                prefix={"Rp. "}
                disabled={isReadonly}
                onChangeInput={(e) => {
                  setValue(
                    `financial_report.${nestIndex}.other_asset.${index}.value`,
                    convertMoney(e.target.value)
                  );
                  sumOtherAssetTotal(nestIndex);
                }}
              />
            </div>
            {
              isReadonly ? null : (
                <button
                  className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                  onClick={() => {
                    removeFinancialReportOtherAsset(index);
                    sumOtherAssetTotal(nestIndex);
                  }}
                >
                  <RiDeleteBin6Line />
                </button>
              )}
          </div>
        ))
        : ""}
      {
        isReadonly ? null : (
          <div className="border-b" />

        )
      }
      {
        isReadonly ? null : (
          <div className="flex flex-row-reverse my-5 mx-4 ">
            <Button
              className="p-4 border rounded-lg"
              label={
                <div className="flex items-center gap-2 font-[14px]">
                  <RiAddLine />
                  Tambah Aktiva Lainnya
                </div>
              }
              onClick={() => {
                // const prevArr = [...getValues("otherAsset")];
                // prevArr.push({
                //   name: "",
                //   value: "",
                // });
                // setOtherAssetCustom(prevArr);
                appendFinancialReportOtherAsset({
                  name: "",
                  value: "",
                });
              }}
            />
          </div>
        )
      }
    </>
  );
};

export default OtherAsset;
