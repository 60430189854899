import React, { useEffect, useMemo, useState } from "react";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormUploadNew as InputFormUpload,
} from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  RiAddLine,
  RiDeleteBin6Line,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { isCitizenIdValid } from "helpers/validation";
import { validationErrorMsg } from "locale/yup_id";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import { getPositionService } from "services/masterData/position";
import { addNewPosition } from "services/danaProgram/masterDataService";

const StrukturOrganisasi = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get("readonly") === "true";
  const [positions, setPositions] = useState();
  const validationSchema = useMemo(() => {
    return yup.object({
      commissioners: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().trim().required().label("Nama"),
            gender: yup.mixed().required().nonNullable().label("Jenis Kelamin"),
            nik: yup
              .string()
              .trim()
              .test(
                "no_ktp",
                ({ label }) =>
                  validationErrorMsg.mixed.default.replace("${path}", label),
                isCitizenIdValid
              )
              .required()
              .label("Nomor KTP"),
            email: yup
              .string()
              .required("email wajib diisi")
              .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                "format email tidak tepat"
              ),
          })
        )
        .min(1),
      directors: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().trim().required().label("Nama"),
            gender: yup.mixed().required().nonNullable().label("Jenis Kelamin"),
            position: yup.mixed().required().nonNullable().label("Jabatan"),
            nik: yup
              .string()
              .trim()
              .test(
                "no_ktp",
                ({ label }) =>
                  validationErrorMsg.mixed.default.replace("${path}", label),
                isCitizenIdValid
              )
              .required()
              .label("Nomor KTP"),

            email: yup.string().email().required().label("Email"),
          })
        )
        .min(1),
      structure_organization_path: yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
        size: yup.string().required(),
        type: yup.string().required(),
        url: yup.string().required(),
      }).required(),
      structure_organization_desc: yup
        .string()
        .required("deskripsi dokumen bukti wajib diisi"),
      job_desc_path: yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
        size: yup.string().required(),
        type: yup.string().required(),
        url: yup.string().required(),
      }).required(),
      job_desc_desc: yup
        .string()
        .required("deskripsi dokumen bukti wajib diisi"),
      sop_path: yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
        size: yup.string().required(),
        type: yup.string().required(),
        url: yup.string().required(),
      }).required(),
      sop_desc: yup.string().required("deskripsi dokumen bukti wajib diisi"),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      commissioners: draftData?.commissioners || [
        {
          name: undefined,
          gender: undefined,
          nik: undefined,
          email: undefined,
        },
      ],
      directors: draftData?.directors || [
        {
          name: undefined,
          gender: undefined,
          position: undefined,
          nik: undefined,
          email: undefined,
        },
      ],
    },
  });

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const {
    fields: commissionerFields,
    append: appendCommissioners,
    remove: removeCommissioners,
  } = useFieldArray({
    control,
    name: "commissioners",
  });

  const {
    fields: directorFields,
    append: appendDirectors,
    remove: removeDirectors,
  } = useFieldArray({
    control,
    name: "directors",
  });

  const [isFetching, setIsFetching] = useState(true);

  const getPosition = useMutation({
    mutationFn: async (callback) => {
      try {
        const positionResponse = await getPositionService();
        const transformedData = positionResponse?.data?.data?.map(
          (item, index) => ({
            label: item.nama,
            value: item.id,
          })
        );
        callback(transformedData);
        setIsFetching(false);
      } catch (error) {
        throw error;
      }
    },
  });

  useEffect(() => {
    getPosition.mutate(setPositions);
  }, []);

  const { setValue } = methods;
  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);

      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: response.data.data.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);
        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: response.data.data.size,
        };
        setValue(key, temp, { shouldValidate: true });
      }
    } catch (error) {
      throw error;
    }
  });

  useEffect(() => {
    if (draftData && Object.keys(draftData).length > 0) {
      Object.keys(draftData).forEach((key) => {
        if (key === "supporting_documents") {
          setValue("structure_organization_desc", draftData[key]?.[0]?.desc);
          getFileMutation.mutate({
            id: draftData[key]?.[0]?.path,
            key: `structure_organization_path`,
          });
          setValue("job_desc_desc", draftData[key]?.[1]?.desc);
          getFileMutation.mutate({
            id: draftData[key]?.[1]?.path,
            key: `job_desc_path`,
          });
          setValue("sop_desc", draftData[key]?.[2]?.desc);
          getFileMutation.mutate({
            id: draftData[key]?.[2]?.path,
            key: `sop_path`,
          });
        }
        setValue(key, draftData[key]);
      });
      methods.trigger();
    }
  }, [draftData, setValue, methods]);

  return (
    <>
      <FormProvider {...methods}>
        <>
          {!isFetching && (
            <>
              <CardForm
                label={"Komisaris / Dewan Pengawasan / Dewan Pembiaan"}
                buttonCollapse={true}
              >
                <>
                  <CardForm
                    label={"Komisaris / Dewan Pengawasan / Dewan Pembiaan"}
                    buttonCollapse={true}
                    childerForm={true}
                  >
                    {commissionerFields.length > 0
                      ? commissionerFields.map((data, index) => (
                        <div
                          className="flex gap-4 items-end border-t pt-2"
                          key={data.id}
                        >
                          <div className="w-full">
                            <div className="flex gap-4">
                              <div className="flex-1 pt-2">
                                <InputForm
                                  controllerName={`commissioners.${index}.name`}
                                  className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.name
                                    ? "border-[#F04438]"
                                    : "border-green"
                                    }`}
                                  label={"Nama"}
                                  placeholder={"Nama"}
                                  required={true}
                                  disabled={isReadonly}
                                />
                              </div>
                              <div className="flex-1 pt-2">
                                <InputForm
                                  controllerName={`commissioners.${index}.nik`}
                                  className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nik
                                    ? "border-[#F04438]"
                                    : "border-green"
                                    }`}
                                  label={"Nomor KTP"}
                                  placeholder={"Nomor KTP"}
                                  required={true}
                                  disabled={isReadonly}
                                />
                              </div>
                            </div>
                            <div className="flex gap-4">
                              <div className="flex-1 pt-2">
                                <AtomSelect
                                  controllerName={`commissioners.${index}.gender`}
                                  options={[
                                    { value: "L", label: "laki-laki" },
                                    { value: "P", label: "perempuan" },
                                  ]}
                                  className={`basic-single mt-2  ${errors.gender
                                    ? "border-[#F04438]"
                                    : "border-green"
                                    }`}
                                  label="Jenis Kelamin"
                                  required={true}
                                  disable={isReadonly}
                                />
                              </div>
                              <div className="flex-1 pt-2">
                                <InputForm
                                  controllerName={`commissioners.${index}.email`}
                                  className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.email
                                    ? "border-[#F04438]"
                                    : "border-green"
                                    }`}
                                  label={"Email"}
                                  placeholder={"Email"}
                                  required={true}
                                  disabled={isReadonly}
                                />
                              </div>
                            </div>
                          </div>
                          {index !== 0 && !isReadonly && (
                            <button
                              className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                              onClick={() => removeCommissioners(index)}
                            >
                              <RiDeleteBin6Line />
                            </button>
                          )}
                        </div>
                      ))
                      : ""}
                  </CardForm>
                  <div className="border"></div>
                  {
                    isReadonly ? "" : (
                      <div className="flex flex-row-reverse my-5 mx-4 ">
                        <Button
                          className="p-4 border rounded-lg"
                          label={
                            <div className="flex items-center gap-2 font-[14px]">
                              <RiAddLine />
                              Tambah Data Lain
                            </div>
                          }
                          onClick={() => {
                            appendCommissioners({
                              name: "",
                              gender: "",
                              nik: "",
                              email: "",
                            });
                          }}
                        />
                      </div>
                    )
                  }
                </>
              </CardForm>

              <CardForm
                label={"Direksi / Ketua Yayasan / Rektor / Dekan 1"}
                buttonCollapse={true}
              >
                <>
                  <CardForm
                    label={"Direksi / Ketua Yayasan / Rektor / Dekan 1"}
                    buttonCollapse={true}
                    childerForm={true}
                  >
                    <>
                      {directorFields.length > 0
                        ? directorFields.map((data, index) => (
                          <div
                            className="flex gap-4 items-end border-t pt-2"
                            key={data.id}
                          >
                            <div className="w-full">
                              <div className="flex gap-4">
                                <div className="flex-1 pt-2">
                                  <InputForm
                                    controllerName={`directors.${index}.name`}
                                    className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.name
                                      ? "border-[#F04438]"
                                      : "border-green"
                                      }`}
                                    label={"Nama"}
                                    placeholder={"nama"}
                                    required={true}
                                    disabled={isReadonly}
                                  />
                                </div>
                                <div className="flex-1 pt-2">
                                  <InputForm
                                    controllerName={`directors.${index}.nik`}
                                    className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.nik
                                      ? "border-[#F04438]"
                                      : "border-green"
                                      }`}
                                    label={"Nomor KTP"}
                                    placeholder={"Nomor KTP"}
                                    required={true}
                                    disabled={isReadonly}
                                  />
                                </div>
                                <div className="flex-1 pt-2">
                                  <AtomSelect
                                    controllerName={`directors.${index}.gender`}
                                    options={[
                                      { value: "L", label: "laki-laki" },
                                      { value: "P", label: "perempuan" },
                                    ]}
                                    className="basic-single mt-2"
                                    label="Jenis Kelamin"
                                    required={true}
                                    disable={isReadonly}
                                  />
                                </div>
                              </div>
                              <div className="flex gap-4">
                                <div className="flex-1 pt-2">
                                  <AtomSelect
                                    controllerName={`directors.${index}.position`}
                                    className={`basic-single mt-2 ${errors.position
                                      ? "border-[#F04438]"
                                      : "border-green"
                                      }`}
                                    label={"Jabatan"}
                                    placeholder={"Jabatan"}
                                    required={true}
                                    options={positions}
                                    mutation={addNewPosition}
                                    type={"dynamic"}
                                    disable={isReadonly}
                                  />
                                </div>
                                <div className="flex-1 pt-2">
                                  <InputForm
                                    controllerName={`directors.${index}.email`}
                                    className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.email
                                      ? "border-[#F04438]"
                                      : "border-green"
                                      }`}
                                    label={"Email"}
                                    placeholder={"Email"}
                                    required={true}
                                    disabled={isReadonly}
                                  />
                                </div>
                              </div>
                            </div>
                            {index !== 0 && !isReadonly && (
                              <button
                                className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                                onClick={() => removeDirectors(index)}
                              >
                                <RiDeleteBin6Line />
                              </button>
                            )}
                          </div>
                        ))
                        : ""}
                    </>
                  </CardForm>
                  <div className="border"></div>
                  {
                    isReadonly ? null : (
                      <div className="flex flex-row-reverse my-5 mx-4 ">
                        <Button
                          className="p-4 border rounded-lg"
                          label={
                            <div className="flex items-center gap-2 font-[14px]">
                              <RiAddLine />
                              Tambah Data Lain
                            </div>
                          }
                          onClick={() => {
                            appendDirectors({
                              name: "",
                              gender: "",
                              position: "",
                              nik: "",
                              email: "",
                            });
                          }}
                        />
                      </div>
                    )
                  }
                </>
              </CardForm>
            </>
          )}
        </>

        <CardForm label={"Lampiran Dokumen pendukung"}>
          <>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormUpload
                  controllerName={`structure_organization_path`}
                  label={
                    "Dokumen struktur organisasi Lembaga/ Instansi/ perusahaan"
                  }
                  required={true}
                  uploadFile={mutation}
                  methods={methods}
                  disabled={isReadonly}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`structure_organization_desc`}
                  className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                  label={"Uraian singkat terkait isi dokumen"}
                  placeholder={"Uraian Singkat Terkait Isi Dokumen"}
                  textArea={true}
                  required={true}
                  disabled={isReadonly}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormUpload
                  controllerName={"job_desc_path"}
                  label={"Dokumen fungsi organ (job descriptions)"}
                  required={true}
                  uploadFile={mutation}
                  methods={methods}
                  disabled={isReadonly}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`job_desc_desc`}
                  className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                  label={"Uraian singkat terkait isi dokumen"}
                  placeholder={"Uraian Singkat Terkait Isi Dokumen"}
                  textArea={true}
                  required={true}
                  disabled={isReadonly}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormUpload
                  controllerName={"sop_path"}
                  label={"Dokumen SoP pengambilan keputusan"}
                  required={true}
                  uploadFile={mutation}
                  methods={methods}
                  disabled={isReadonly}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`sop_desc`}
                  className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                  label={"Uraian singkat terkait isi dokumen"}
                  placeholder={"Uraian Singkat Terkait Isi Dokumen"}
                  textArea={true}
                  required={true}
                  disabled={isReadonly}
                />
              </div>
            </div>
          </>
        </CardForm>

        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          hideDraft={
            isReadonly
          }
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={!isValid}
          submitActionButton={() => {
            console.error(errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default StrukturOrganisasi;
