import React, { useMemo, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormUploadNew as InputFormUpload,
} from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import CardForm from "components/molecules/CardForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiAddLine, RiDeleteBin6Line, RiQuestionLine } from "react-icons/ri";
import AtomDatePicker from "components/atoms/Datepicker";
import AtomSelect from "components/atoms/Select";
import { validationErrorMsg, validationCustomErrorMsg } from "locale/yup_id";
import { isNumberValid } from "helpers/validation";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import { useState } from "react";

const PeralatanPerlengkapan = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get("readonly") === "true";
  const [defaultValue, setDefaultValue] = useState();
  const validationSchema = useMemo(() => {
    return yup.object({
      instrumentations: yup.array().of(
        yup.object().shape({
          name: yup.string().required(`nama wajib diisi`),
          category: yup
            .object({
              value: yup
                .string()
                .required("kategori wajib diisi")
                .label("kategori"),
            })
            .required("kategori wajib diisi")
            .label("kategori"),
          production_year: yup
            .string()
            .trim()
            .test(
              "production_year",
              ({ label }) =>
                validationErrorMsg.number.default.replace("${path}", label),
              isNumberValid
            )
            .required("tahun pembuatan wajib diisi")
            .label("tahun pembuatan"),
          capacity: yup.string().required("kapasitas saat ini wajib diisi"),
          total: yup
            .number("total harus merupakan angka")
            .required("jumlah wajib diisi"),
          condition: yup.string().required("kondisi alat wajib diisi"),
          location: yup.string().required("lokasi alat wajib diisi"),
          url_file_proof_of_ownership: yup.mixed(),
          mark_and_type: yup.string().required("mark & tipe wajib diisi"),
        })
      ),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: defaultValue || "",
  });

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const {
    fields: instrumentationFields,
    append: appendInstrumentations,
    remove: removeInstrumentations,
  } = useFieldArray({
    control,
    name: "instrumentations",
  });

  function removeDuplicatesFromInstrumentations() {
    const seenNames = new Set();
    const indexesToRemove = [];

    instrumentationFields.forEach((item, index) => {
      if (seenNames.has(item.name)) {
        indexesToRemove.push(index);
      } else {
        seenNames.add(item.name);
      }
    });

    indexesToRemove.reverse().forEach((index) => {
      removeInstrumentations("instrumentations", index);
    });
  }

  const appendingValue = (data) => {
    data.forEach((data, index) => {
      appendInstrumentations({
        name: data.name,
        category: data.category,
        production_year: data.production_year,
        capacity: data.capacity,
        total: data.total,
        condition: data.condition,
        location: data.location,
        url_file_proof_of_ownership: getFileMutation.mutate({
          id: data.url_file_proof_of_ownership,
          key: `instrumentations.${index}.url_file_proof_of_ownership`,
        }),
        mark_and_type: data.mark_and_type,
      });
    });
  };

  const { setValue } = methods;
  useEffect(() => {
    if (draftData?.list?.length > 0) {
      appendingValue(draftData?.list);
      methods.trigger();
    } else {
      if (instrumentationFields.length === 0) {
        appendInstrumentations({
          name: "",
          category: null,
          production_year: "",
          capacity: "",
          total: "",
          condition: "",
          location: "",
          url_file_proof_of_ownership: "",
          mark_and_type: "",
        });
      }
    }
  }, [draftData, methods, setValue]);

  useEffect(() => {
    removeDuplicatesFromInstrumentations();
  }, [instrumentationFields]);

  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);

      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: response.data.data.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);
        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: response.data.data.size,
        };
        setValue(key, temp);
      }
    } catch (error) {
      throw error;
    }
  });

  return (
    <>
      <FormProvider {...methods}>
        <CardForm label={"Peralatan / Perlengkapan"}>
          <>
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg">
                <RiQuestionLine />
                Apa saja fasilitas fisik/ infrastruktur yang dimiiki oleh
                lembaga/ instansi/ perusahaan Anda untuk mengelola dan
                menjalankan program?
              </div>
            </div>

            <>
              {instrumentationFields.length > 0
                ? instrumentationFields.map((data, index) => (
                  <CardForm
                    label={`Alat ${index + 1}`}
                    buttonCollapse={true}
                    childerForm={true}
                  >
                    <div
                      className="flex gap-4 items-end border-t pt-2"
                      key={data.id}
                    >
                      <div className="w-full">
                        <div className="flex gap-4">
                          <div className="flex-1 pt-2">
                            <InputForm
                              controllerName={`instrumentations.${index}.name`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.name
                                ? "border-[#F04438]"
                                : "border-green"
                                }`}
                              label={"Nama Alat"}
                              placeholder={"Nama Alat"}
                              required={true}
                              disabled={isReadonly}
                            />
                          </div>
                          <div className="flex-1 pt-2">
                            <AtomSelect
                              controllerName={`instrumentations.${index}.category`}
                              className={`mt-2 w-full rounded-md ${errors.category
                                ? "border-[#F04438]"
                                : "border-green"
                                }`}
                              options={[
                                {
                                  value: "Fasilitas umum tinggi",
                                  label: "Fasilitas umum tinggi",
                                },
                                {
                                  value: "Fasilitas umum menengah",
                                  label: "Fasilitas umum menengah",
                                },
                                {
                                  value: "Fasilitas umum rendah",
                                  label: "Fasilitas umum rendah",
                                },
                              ]}
                              label={"Kategori"}
                              placeholder={"Kategori"}
                              required={true}
                              disable={isReadonly}
                            />
                          </div>
                        </div>
                        <div className="flex gap-4">
                          <div className="flex-1 pt-2">
                            <InputForm
                              controllerName={`instrumentations.${index}.total`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.total
                                ? "border-[#F04438]"
                                : "border-green"
                                }`}
                              label={"Jumlah"}
                              placeholder={"jumlah"}
                              required={true}
                              disabled={isReadonly}
                            />
                          </div>
                          <div className="flex-1 pt-2">
                            <InputForm
                              controllerName={`instrumentations.${index}.capacity`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.capacity
                                ? "border-[#F04438]"
                                : "border-green"
                                }`}
                              label="Kapasitas Saat ini"
                              placeholder={"Kapasitas Saat ini"}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="flex gap-4">
                          <div className="flex-1 pt-2">
                            <InputForm
                              controllerName={`instrumentations.${index}.mark_and_type`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.mark_and_type
                                ? "border-[#F04438]"
                                : "border-green"
                                }`}
                              label={"Mark & Tipe"}
                              placeholder={"Mark & Tipe"}
                              required={true}
                              disabled={isReadonly}
                            />
                          </div>
                          <div className="flex-1 pt-2">
                            <InputForm
                              controllerName={`instrumentations.${index}.production_year`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.production_year
                                ? "border-[#F04438]"
                                : "border-green"
                                }`}
                              label="Tahun Pembuatan"
                              placeholder={"Tahun Pembuatan"}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="flex gap-4">
                          <div className="flex-1 pt-2">
                            <InputForm
                              controllerName={`instrumentations.${index}.condition`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.condition
                                ? "border-[#F04438]"
                                : "border-green"
                                }`}
                              label={"Kondisi"}
                              placeholder={"Kondisi"}
                              required={true}
                              disabled={isReadonly}
                            />
                          </div>
                          <div className="flex-1 pt-2">
                            <InputForm
                              controllerName={`instrumentations.${index}.location`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md ${errors.location
                                ? "border-[#F04438]"
                                : "border-green"
                                }`}
                              label={"Lokasi Alat"}
                              placeholder={"Lokasi Alat"}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="flex gap-4">
                          <div className="flex-1 pt-2">
                            <InputFormUpload
                              controllerName={`instrumentations.${index}.url_file_proof_of_ownership`}
                              label={"Bukti kepemilikan"}
                              uploadFile={mutation}
                              methods={methods}
                              disabled={isReadonly}
                            />
                          </div>
                        </div>
                      </div>
                      {index !== 0 && !isReadonly && (
                        <button
                          className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                          onClick={() => removeInstrumentations(index)}
                        >
                          <RiDeleteBin6Line />
                        </button>
                      )}
                    </div>
                  </CardForm>
                ))
                : ""}
            </>
            <div className="border"></div>
            {
              !isReadonly && (
                <div className="flex flex-row-reverse my-5 mx-4 ">
                  <Button
                    className="p-4 border rounded-lg"
                    label={
                      <div className="flex items-center gap-2 font-[14px]">
                        <RiAddLine />
                        Tambah Alat
                      </div>
                    }
                    onClick={() => {
                      appendInstrumentations({
                        name: "",
                        category: "",
                        production_year: "",
                        capacity: "",
                        total: "",
                        condition: "",
                        location: "",
                        url_file_proof_of_ownership: "",
                        mark_and_type: "",
                      });
                    }}
                  />
                </div>
              )
            }
          </>
        </CardForm>

        <BottomFormAction
          lastStep={true}
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          hideDraft={isReadonly}
          hideSubmit={isReadonly}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            console.log(errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default PeralatanPerlengkapan;
