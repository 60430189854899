import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const BranchesInput = ({ controllerName, className, label, required, disabled = false }) => {
  const { control, setValue } = useFormContext(); // Destructure setValue from useFormContext
  const [valueState, setValueState] = useState(2); // Initial value, you can change it accordingly

  useEffect(() => {
    setValue(controllerName, valueState); // Use setValue instead of control.setValue
  }, [valueState, controllerName, setValue]); // Adjust dependencies

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <div className={['form-control w-full', className || ''].join(' ')}>
            {label && (
              <label className="label flex gap-1 font-semibold text-[14px] ">
                <span className={`label-text`}>{label}</span>
                {required && <span className="text-[#F04438]">*</span>}
              </label>
            )}

            <div className="flex items-center gap-2">
              <input
                type="number"
                min="1"
                value={value ? value : valueState}
                onChange={(e) => setValueState(parseInt(e.target.value))}
                className="border p-2 rounded-md w-full text-center my-2"
                disabled={disabled}
              />
            </div>

            {invalid && (
              <label className="label">
                <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                  {error?.message}
                </div>
              </label>
            )}
          </div>
        );
      }}
    />
  );
};

export default BranchesInput;
