import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  Spinner,
} from 'components';
import Radio from 'components/atoms/Radio';
import { AlertWarning } from 'components/molecules/Alert';
import QuestionBadge from 'components/molecules/Badge/QuestionBadge';
import CardForm from 'components/molecules/CardForm';
import FileDetail from 'components/molecules/FileDetail';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import React, { Fragment, useEffect, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  getDetailSafeguards,
  postSafeguardsStep3,
} from 'services/danaProgram/callForProposalService';
import { fileUpload } from 'services/danaProgram/fileService';
import { getInitiatorList } from 'services/masterData/initiatorCapacity';
import * as yup from 'yup';

const schema = yup.object({
  initiator: yup.array().of(
    yup.object().shape({
      is_true: yup.boolean().required('Wajib diisi'),
      documents: yup.mixed(),
    })
  ),
});

const InitiatorCapacity = ({ onNext, onBack, type }) => {
  const navigate = useNavigate();
  const { id, editable } = useSelector(
    (state) => state?.callForProposalData?.data
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      initiator: [],
    },
  });

  const { data: dataDetail, isFetching: isFetchingDetail } = useQuery({
    queryKey: ['get-detail-safeguard-3'],
    queryFn: async () => {
      const res = await getDetailSafeguards({ proposal_id: id });
      return res.data.data;
    },
  });

  const { control, setValue, getValues } = methods;

  const { data, isLoading } = useQuery({
    queryKey: ['get-master-initiator'],
    queryFn: async () => {
      const res = await getInitiatorList();
      return res.data.data;
    },
  });

  useEffect(() => {
    if (data && dataDetail) {
      if (!!dataDetail?.initiator) {
        methods.reset({
          id: dataDetail?._id,
          initiator: dataDetail?.initiator,
        });
      } else {
        methods.reset({
          id: dataDetail?._id,
          initiator: data?.map((item) => {
            return {
              initiator_id: item.id,
              value: item?.value,
              name: item?.nama,
              documents: [],
            };
          }),
        });
      }
    }
  }, [data, dataDetail]);

  const onSubmit = async () => {
    const initiator = methods.getValues('initiator');

    const uploadPromises = initiator.map(async (item, index) => {
      if (item?.documents?.length > 0) {
        try {
          const result = await postFile.mutateAsync({
            file: item.documents,
            index,
          });
          if (result.data[0] !== undefined) {
            setValue(`initiator.${index}.documents`, result.data);
          }
          return result;
        } catch (error) {
          throw error; // Rethrow to be caught by Promise.all
        }
      } else {
        return Promise.resolve('No documents to upload for this initiator.');
      }
    });

    try {
      const results = await Promise.all(uploadPromises);
      // Now that all files are uploaded, proceed with submitting the initiator
      submitinitiator.mutate(methods.getValues());
    } catch (error) {
      console.error('An error occurred during file upload:', error);
    }
  };

  const postFile = useMutation({
    mutationKey: ['post-file'],
    mutationFn: async (payload) => {
      // Make this function async
      const data = await Promise.all(
        payload.file?.map(async (item) => {
          // Await the resolution of all file uploads
          if (!item?.fileId) {
            const formData = new FormData();
            formData.append('file', item);
            const res = await fileUpload(formData);
            return {
              fileId: res.data.data.id,
              fileName: res.data.data.name,
              fileSize: res.data.data.size,
              mimeType: res.data.data.mimeType,
              path: res.data.data.key,
            };
          }
        }) || []
      ); // Ensure fallback to an empty array if payload.file is undefined
      return {
        data, // This now waits for all the uploads before proceeding
        index: payload.index,
      };
    },
  });

  const submitinitiator = useMutation({
    mutationKey: ['post-initiator'],
    mutationFn: async (payload) => {
      const res = await postSafeguardsStep3(payload);
      return res;
    },
    onSuccess: (res) => {
      onNext(type);
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'initiator',
    keyName: '_id',
  });

  return (
    <Fragment>
      <AlertWarning
        children={
          <div>
            <p>Lengkapi Data</p>
            <small className="font-normal text-slate-700">
              Silahkan isi semua data dibawah ini untuk bisa melanjutkan
              pengisian ke tahap selanjutnya
            </small>
          </div>
        }
      />
      <FormProvider {...methods}>
        <CardForm label="Kapasitas Pemrakarsa">
          {isLoading ? (
            <div className="w-full flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              {fields?.map((item, index) => (
                <NestedQuestions
                  key={item?._id}
                  item={item}
                  alias={`initiator.${index}`}
                  index={index}
                  editable={editable}
                />
              ))}
            </>
          )}
        </CardForm>
        <BottomFormAction
          lastStep={false}
          backButtonAction={() => onBack()}
          disableDrafButton={false}
          draftLabel={'Kembali Ke Proposal'}
          drafButtonAction={() => {
            navigate(`/proposal/call-for-proposal/${id}`);
          }}
          isLoading={postFile.isLoading || submitinitiator.isLoading}
          // disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </Fragment>
  );
};

const NestedQuestions = ({ item, alias, index, editable }) => {
  const { setValue, getValues } = useFormContext();

  const [initDocs, setInitDocs] = useState(item?.documents);

  const radioForm = useWatch({
    name: `${alias}.is_true`,
  });

  useEffect(() => {
    if (radioForm) {
      setValue(`${alias}.value`, item.value);
    }
  }, [radioForm]);

  return (
    <div className="flex flex-col gap-4">
      <QuestionBadge text={item?.name} />
      <InputFormRadio
        controllerName={`${alias}.is_true`}
        disabled={!editable}
        values={[
          {
            label: 'Ya',
            value: true,
          },
          {
            label: 'Tidak',
            value: false,
          },
        ]}
      />
      {initDocs?.length > 0 ? (
        <>
          {item?.documents?.map((item, index) => (
            <FileDetail
              key={index}
              file={item}
              hideDelete={!editable}
              onRemove={() => {
                const filtered = initDocs.filter((file) => file.id !== item.id);
                setInitDocs(filtered);
                setValue(`${alias}.documents`, filtered);
              }}
            />
          ))}
        </>
      ) : (
        <InputFormDropzone
          name={`${alias}.documents`}
          disabled={!editable}
          accept={{
            'image/png': [],
            'image/jpeg': [],
            'application/pdf': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              [],
          }}
          maxFiles={1}
          maxSize={10}
          multiple={false}
          informationText="PNG, JPG, PDF, Docx"
        />
      )}
      <hr />
    </div>
  );
};

export default InitiatorCapacity;
