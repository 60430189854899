import React, { Fragment, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  BottomFormAction,
  Button,
  ConfirmationModal,
  InputForm,
  Spinner,
} from 'components';
import AlertBlankData from 'components/molecules/Alert';
import CardForm from 'components/molecules/CardForm';
import { RiAddLine, RiDeleteBinLine } from 'react-icons/ri';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fileUpload } from 'services/danaProgram/fileService';
import {
  postBasicInformationSafeGuards,
  getDetailSafeguards,
} from 'services/danaProgram/callForProposalService';
import { useDispatch, useSelector } from 'react-redux';
import { saveIdSafeGuard } from 'app/actions';
import FileDetail from 'components/molecules/FileDetail';
import { useNavigate } from 'react-router';

const validationSchema = yup.object({
  policy: yup
    .string()
    .required('Kebijakan Perlindungan Lingkungan wajib diisi'),
  intervention: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Kegiatan Ekonomi/ Lingkungan wajib diisi'),
    })
  ),
  involvement_scope: yup.array().of(
    yup.object().shape({
      rights_holder: yup
        .string()
        .required('Pemangku Hak & Kepentingan wajib diisi'),
      role: yup
        .string()
        .required('Peran Pemangku Hak & Kepentingan wajib diisi'),
    })
  ),
});

const defaultValues = {
  intervention: [{ name: '' }],
  involvement_scope: [
    {
      rights_holder: '',
      role: '',
    },
  ],
};

const NestedInterventions = ({ editable }) => {
  const { control } = useFormContext();
  const {
    fields: interventionFields,
    append: appendIntevention,
    remove: removeIntevention,
  } = useFieldArray({
    control,
    name: `intervention`,
  });

  return (
    <div>
      <div className="flex gap-1 font-semibold text-[14px] mb-2">
        <span className={`label-text`}>
          Kegiatan Ekonomi/ Lingkungan yang Sudah Dilakukan Sejak Terbitnya Izin
        </span>
        <span className="text-[#F04438]">*</span>
      </div>
      <div className="space-y-5">
        {interventionFields.map((field, key) => (
          <div className="flex gap-6 items-end" key={field.id}>
            <InputForm
              controllerName={`intervention.${key}.name`}
              className={`py-2 px-4 border w-full rounded-md flex-1`}
              textArea={true}
              rows={5}
              disabled={!editable}
            />
            {key === 0 && editable && (
              <Button
                type="button"
                className="rounded-lg bg-primary-600 text-white shadow py-2 px-3.5 items-center font-semibold h-10"
                label={<RiAddLine size={20} className="text-white" />}
                onClick={() => appendIntevention({ name: '' })}
              />
            )}
            {editable && key !== 0 && (
              <Button
                type="button"
                className="rounded-lg bg-error-600 text-white shadow py-2 px-3.5 items-center font-semibold h-10"
                label={<RiDeleteBinLine size={20} className="text-white" />}
                onClick={() => {
                  removeIntevention(key);
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const BasicInformation = ({ onNext, onBack, type }) => {
  const [payload, setPayload] = useState(null);
  const [fileDoc, setFileDoc] = useState([]);
  const { id, editable } = useSelector(
    (state) => state?.callForProposalData?.data
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues,
  });

  const { control } = methods;

  const submitForm = (values) => {
    let payload = {
      proposal_id: id,
      intervention: values.intervention.map((el) => el),
      ...values,
    };
    setPayload(payload);
    postFile.mutate();
  };

  const { data: dataDetail, isLoading } = useQuery({
    queryKey: ['get-detail-safeguard'],
    queryFn: async () => {
      const res = await getDetailSafeguards({ proposal_id: id });
      return res.data.data;
    },
    enabled: !!id,
  });

  useEffect(() => {
    if (dataDetail?.basic_information) {
      const { basic_information } = dataDetail;
      methods.reset({
        intervention: basic_information.intervention,
        involvement_scope: basic_information.involvement_scope,
        policy: basic_information.policy,
        document: [],
      });
      setFileDoc(basic_information.supportive_doc);
    }
  }, [dataDetail]);

  const postFile = useMutation({
    mutationKey: ['post-file-document'],
    mutationFn: async () => {
      const promise = payload?.document?.map(async (item) => {
        const formData = new FormData();
        formData.append('file', item);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      });

      return Promise.all(promise);
    },
    onSuccess: async (data) => {
      postBasicInformation.mutate({
        ...payload,
        id: dataDetail?._id || '',
        document: data?.[0] ? [data?.[0], ...(fileDoc || [])] : [...fileDoc],
      });
    },
  });

  const postBasicInformation = useMutation({
    mutationKey: ['post-safeguard-basic-information'],
    mutationFn: async (payload) => {
      const res = await postBasicInformationSafeGuards(payload);
      return res;
    },
    onSuccess: (data) => {
      dispatch(saveIdSafeGuard(data?.data?.data?.id));
      onNext(type, payload);
    },
  });

  const {
    fields: holderFields,
    append: appendHolder,
    remove: removeHolder,
  } = useFieldArray({
    control,
    name: `involvement_scope`,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {id && isLoading ? (
        <div className="w-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="space-y-6">
          <div className="w-full">
            <AlertBlankData
              title={`Lengkapi Data`}
              body={`Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
            />
          </div>
          <FormProvider {...methods}>
            <CardForm label="Informasi Proyek dan atau kegiatan yang berhubungan dengan perlindungan lingkungan dan sosial">
              <div className="space-y-5">
                <NestedInterventions editable={editable} />
                <div className="flex gap-1 font-semibold text-[14px] mb-2">
                  <span className={`label-text`}>
                    Lingkup Pelibatan Para Pemangku Hak dan kepentingan yang
                    relevan dan peranannya dalam kegiatan yang diusulkan
                  </span>
                </div>
                <div className="space-y-5">
                  {holderFields.map((field, key) => (
                    <div className="flex gap-6 items-end" key={field.id}>
                      <div className="flex-1">
                        <div className="flex gap-1 font-semibold text-[14px] mb-2">
                          <span className={`label-text`}>
                            Pemangku Hak & Kepentingan
                          </span>
                          <span className="text-[#F04438]">*</span>
                        </div>
                        <InputForm
                          controllerName={`involvement_scope.${key}.rights_holder`}
                          className={`py-2 px-4 border w-full rounded-md flex-1`}
                          disabled={!editable}
                        />
                      </div>
                      <div className="flex-1">
                        <div className="flex gap-1 font-semibold text-[14px] mb-2">
                          <span className={`label-text`}>Peran</span>
                          <span className="text-[#F04438]">*</span>
                        </div>
                        <InputForm
                          controllerName={`involvement_scope.${key}.role`}
                          className={`py-2 px-4 border w-full rounded-md flex-1`}
                          disabled={!editable}
                        />
                      </div>
                      {key === 0 && editable && (
                        <Button
                          type="button"
                          className="rounded-lg bg-primary-600 text-white shadow py-2 px-3.5 items-center font-semibold h-10"
                          label={<RiAddLine size={20} className="text-white" />}
                          onClick={() =>
                            appendHolder({
                              intervention: '',
                            })
                          }
                        />
                      )}
                      {key !== 0 && editable && (
                        <Button
                          type="button"
                          className="rounded-lg bg-error-600 text-white shadow py-2 px-3.5 items-center font-semibold h-10"
                          label={
                            <RiDeleteBinLine size={20} className="text-white" />
                          }
                          onClick={() => {
                            removeHolder(key);
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
                <div>
                  <div className="flex gap-1 font-semibold text-[14px] mb-2">
                    <span className={`label-text`}>
                      Kebijakan Perlindungan Lingkungan dan sosial
                    </span>
                  </div>
                  <div className="flex gap-1 font-semibold text-[14px] mb-2">
                    <span className={`label-text`}>
                      Kebijakan / Implementasi Perlindungan Lingkungan Dan
                      sosial atas proyek yang diusulkan
                    </span>
                    <span className="text-[#F04438]">*</span>
                  </div>
                  <InputForm
                    controllerName={`policy`}
                    className={`py-2 px-4 border w-full rounded-md flex-1`}
                    textArea={true}
                    rows={5}
                    disabled={!editable}
                  />
                </div>
                <div className="space-y-2">
                  <div className="text-[#1D2939] font-semibold text-sm">
                    Dokumen Bukti Pendukung
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </div>
                  {fileDoc.length > 0 ? (
                    fileDoc.map((item, key) => (
                      <FileDetail
                        key={'file-doc-' + key}
                        file={item}
                        hideDelete={!editable}
                        onRemove={() => {
                          const filtered = fileDoc.filter(
                            (file) => file.fileId !== item.fileId
                          );
                          setFileDoc(filtered);
                        }}
                      />
                    ))
                  ) : (
                    <InputFormDropzone
                      name="document"
                      accept={{
                        'image/png': [],
                        'image/jpeg': [],
                        'application/pdf': [],
                      }}
                      disabled={!editable}
                      maxFiles={1}
                      maxSize={10}
                      multiple={false}
                      informationText="PNG, JPG, PDF"
                    />
                  )}
                </div>
              </div>
            </CardForm>
            <BottomFormAction
              lastStep={false}
              backButtonAction={() => onBack()}
              disableDrafButton={false}
              draftLabel={'Kembali Ke Proposal'}
              drafButtonAction={() => {
                navigate(`/proposal/call-for-proposal/${id}`);
              }}
              isLoading={postFile.isLoading || postBasicInformation.isLoading}
              // disableButtonSubmit={isValid ? false : true}
              submitActionButton={() => {
                methods.handleSubmit(submitForm)();
              }}
            />
          </FormProvider>
        </div>
      )}
    </Fragment>
  );
};

export default BasicInformation;
