/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { getHomepageStatus } from 'services/danaProgram/homepage';

import PenilaianLemtaraChild from './penilaianLemtaraChild';

const PenilaianLemtara = () => {
  const [statusComplete, setStatusComplete] = useState(false);
  const [homepageData, setHomepageData] = useState({});

  let userId = localStorage.getItem('userId');

  const mutation = useMutation({
    mutationFn: async () => {
      try {
        const promise = await getHomepageStatus(userId);
        setHomepageData(promise?.data?.body);
        setStatusComplete(areAllStatusesSubmitted(promise?.data?.body));
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  });

  function areAllStatusesSubmitted(data) {
    for (let key in data) {
      if (data[key].status !== 'SUBMITTED') {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (userId) {
      mutation.mutate();
    }
  }, [userId]);

  return (
    <div className="w-full space-y-4">
      <PenilaianLemtaraChild
        statusComplete={statusComplete}
        data={homepageData}
        userId={userId}
      />
    </div>
  );
};

export default PenilaianLemtara;
