import React, { useEffect } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import AlertBlankData from "components/molecules/Alert";
import { BottomFormAction, ConfirmationModal, HeaderLabel } from "components";
import { FormProvider } from "react-hook-form";
import { createPortal } from "react-dom";
import useSafeguards from "./hooks/useSafeguards";
import SafeGuard from "./Form/safeGuard";

const Safeguards = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    resetField,
    formState: { errors, isSubmitting, isValid },
    setValue,
    getValues,
    watch,
    onDraftSubmit,
    onBack,
    onSubmit,
    onSubmitConfirmModal,
    onSubmitConfirmBackModal,
    onSubmitConfirmDraftModal,
    dataSafeguardFields,
    appendDataSafeguard,
    removeDataSafeguard,
    methods,
    temp,
    setTemp,
    consumeAPI,
    dispatch,
    confirmModal,
    setConfirmModal,
    confirmBackModal,
    setConfirmBackModal,
    confirmDraftModal,
    setConfirmDraftModal,
    safeguardDraftData,
    setSafeguardDraftData,
    safeguardsData,
    isLoadingSafeguardData,
    isFetchingSafeguardData,
    refetchSafeguardData,
    onUploadFile,
  } = useSafeguards();

  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get("readonly") === "true";

  return (
    <>
      {createPortal(
        <ConfirmationModal
          open={confirmModal}
          onCancel={() => setConfirmBackModal(false)}
          onClose={setConfirmModal}
          onSubmit={async () => await onSubmitConfirmModal()}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin akan submit semua data ini?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda submit anda masih bisa mengubah data.
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {createPortal(
        <ConfirmationModal
          open={confirmDraftModal}
          onCancel={() => setConfirmBackModal(false)}
          onClose={setConfirmDraftModal}
          onSubmit={async () => await onSubmitConfirmDraftModal()}
          className="space-y-2 mb-4"
        >
          <div className="text-lg font-[600]">
            Anda yakin menyimpan ini sebagai draft?
          </div>
          <div className="text-sm font-[400]">
            Setelah anda menyimpan sebagai draft anda masih bisa melanjutkan.
          </div>
        </ConfirmationModal>,
        document.body
      )}
      {createPortal(
        <ConfirmationModal
          open={confirmBackModal}
          onClose={setConfirmBackModal}
          onCancel={() => setConfirmBackModal(false)}
          onSubmit={() => onSubmitConfirmBackModal()}
          className="space-y-2 mb-4"
        >
          <div>
            <div className="text-lg font-[600]">
              Apakah anda yakin untuk kembali?
            </div>
            <div className="text-sm font-[400]">
              Setelah anda kembali semua data akan disimpan pada draft.
            </div>
          </div>
        </ConfirmationModal>,
        document.body
      )}
      <FormProvider {...methods}>
        <div className="w-full space-y-6 bg-white  rounded-3xl border p-10 mb-6">
          <BreadCrumbs
            routes={[
              {
                label: "Penilaian LEMTARA",
                path: "/penilaian-lemtara",
              },
              {
                label: "Safeguards",
                path: "/",
              },
            ]}
          />

          <div className="flex">
            <HeaderLabel
              text="Safeguard"
              description="Silahkan daftar menggunakan data yang Anda miliki."
            />
          </div>

          <div className="w-full pt-4">
            <AlertBlankData
              title={`Lengkapi Data`}
              body={`Silahkan isi semua data di bawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
            />
          </div>
          <SafeGuard
            safeguardFields={dataSafeguardFields}
            appendSafeguard={appendDataSafeguard}
            removeSafeguard={removeDataSafeguard}
            uploadFile={onUploadFile}
            methods={methods}
          />
        </div>

        <BottomFormAction
          lastStep={true}
          backButtonAction={async () => {
            const values = getValues();
            await onBack(values);
          }}
          hideDraft={isReadonly}
          hideSubmit={isReadonly}
          disableDrafButton={false}
          drafButtonAction={async () => {
            const values = getValues();
            await onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={handleSubmit(async (d) => {
            await onSubmit(d);
          })}
        />
      </FormProvider>
    </>
  );
};

export default Safeguards;
