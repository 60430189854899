import {
  Button,
  InputForm,
  InputFormRadio,
  InputFormUploadNew as InputFormUpload,
} from 'components';
import AtomDatePicker from 'components/atoms/Datepicker';
import AtomSelect from 'components/atoms/Select';
import QuestionBadge from 'components/molecules/Badge/QuestionBadge';
import CardForm from 'components/molecules/CardForm';
import { useFormContext } from 'react-hook-form';
import { RiAddLine, RiDeleteBin6Line } from 'react-icons/ri';

const PengalamanPengelolaanProgram = ({
  programFields = [],
  appendPrograms = () => { },
  removePrograms = () => { },
  uploadFile = () => { },
  methods,
  ThematicProgramsOption = [],
}) => {
  const { watch, getValues, setValue } = useFormContext();
  const params = new URLSearchParams(window.location.search);
  const isReadonly = params.get('readonly') === 'true';

  const convertMoney = (index, field, value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes('.') ? oriValue.split('.').join('') : oriValue;
    let modifValue = '';
    let count = 1;
    if (oriValue !== '' && oriValue.length > 3) {
      const splitValue = oriValue.split('');
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    setValue(`programme.${index}.contract_value`, modifValue);
  };

  return (
    <CardForm label={'Pengalaman Pengelolaan Program'}>
      <QuestionBadge text="Kegiatan apa saja yang sudah lembaga/ institusi/ perusahaan Anda lakukan terkait perlindungan dan pengelolaan lingkungan hidup selama 5 tahun terakhir?" />
      {programFields?.length > 0 &&
        programFields?.map((data, index) => (
          <CardForm
            label={`Pengelolaan Program ${index + 1}`}
            buttonCollapse={true}
          >
            <div className="flex gap-4 items-end border-t pt-2" key={data.id}>
              <div className="w-full">
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`programme.${index}.name`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md`}
                      label={'Nama Program'}
                      placeholder={'Nama Program'}
                      required={true}
                      disabled={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <AtomSelect
                      controllerName={`programme.${index}.program_relevance`}
                      options={ThematicProgramsOption}
                      className="basic-single mt-2"
                      label="Relevansi dengan program BPDLH"
                      required={true}
                      returnObject={true}
                      disable={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`programme.${index}.desc`}
                      className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                      label={'Deskripsi'}
                      placeholder={'Deskripsi'}
                      textArea={true}
                      required={true}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <AtomDatePicker
                      controllerName={`programme.${index}.year`}
                      isRangeDatePicker={true}
                      label="Tahun Pelaksanaan"
                      placeholder=""
                      required={true}
                      showErrorLabel
                      showMonthYearPicker
                      format="MM/yyyy"
                      asText={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`programme.${index}.execution_duration`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md`}
                      label={'Durasi Pelaksanaan'}
                      placeholder={'Durasi Pelaksanaan'}
                      required={true}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`programme.${index}.donor_name`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md`}
                      label={'Nama Donor'}
                      placeholder={'Nama Donor'}
                      required={true}
                      disabled={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <InputForm
                      showPrefix={true}
                      prefix={'Rp. '}
                      controllerName={`programme.${index}.contract_value`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md`}
                      label={'Nilai Kontrak'}
                      placeholder={'Nilai Kontrak'}
                      onChangeInput={(e) =>
                        convertMoney(index, `contract_value`, e.target.value)
                      }
                      required={true}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormUpload
                      controllerName={`programme.${index}.document_contract`}
                      label={'Dokumen Kontrak'}
                      uploadFile={uploadFile}
                      methods={methods}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormRadio
                      label={
                        'Di audit atau tidak oleh Kantor Akuntan Publik (KAP)'
                      }
                      controllerName={`programme.${index}.is_audit`}
                      values={[
                        {
                          label: 'ya',
                          value: true,
                        },
                        {
                          label: 'tidak',
                          value: false,
                        },
                      ]}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
                {watch(`programme.${index}.is_audit`) === true && (
                  <>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <AtomSelect
                          controllerName={`programme.${index}.auditor`}
                          options={[
                            {
                              value: 'Kantor Akuntan Bandung',
                              label: 'Kantor Akuntan Bandung',
                            },
                            {
                              value: 'Kantor Akuntan Jakarta',
                              label: 'Kantor Akuntan Jakarta',
                            },
                            {
                              value: 'Kantor Akuntan Surabaya',
                              label: 'Kantor Akuntan Surabaya',
                            },
                            {
                              value: 'Kantor Akuntan Bogor',
                              label: 'Kantor Akuntan Bogor',
                            },
                          ]}
                          className="basic-single mt-2"
                          label="KAP yang Mengaudit"
                          required={true}
                          returnObject={false}
                          disable={isReadonly}
                        />
                      </div>
                      <div className="flex-1 pt-2">
                        <AtomSelect
                          controllerName={`programme.${index}.result_audit`}
                          options={[
                            {
                              value: 'Wajar Tanpa Pengecualian',
                              label: 'Wajar Tanpa Pengecualian',
                            },
                            {
                              value: 'Wajar Dengan Pengecualian',
                              label: 'Wajar Dengan Pengecualian',
                            },
                            {
                              value: 'Tidak Memberikan Pendapat',
                              label: 'Tidak Memberikan Pendapat',
                            },
                            {
                              value: 'Tidak Wajar',
                              label: 'Tidak Wajar',
                            },
                          ]}
                          className="basic-single mt-2"
                          label="Hasil Penilaian Audit"
                          required={true}
                          returnObject={false}
                          disable={isReadonly}
                        />
                        {/* <InputForm
                          controllerName={`programme.${index}.result_audit`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Hasil Penilaian Audit"}
                          placeholder={"Hasil Penilaian Audit"}
                          required={true}
                        /> */}
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputFormUpload
                          controllerName={`programme.${index}.document_audit`}
                          label={'Dokumen Hasil Audit'}
                          uploadFile={uploadFile}
                          methods={methods}
                          disabled={isReadonly}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="flex gap-4">
                  <div className="flex-1 pt-2">
                    <InputFormUpload
                      controllerName={`programme.${index}.report_programme`}
                      label={'Laporan Program/Proyek'}
                      uploadFile={uploadFile}
                      methods={methods}
                      disabled={isReadonly}
                    />
                  </div>
                  <div className="flex-1 pt-2">
                    <InputForm
                      controllerName={`programme.${index}.desc_report_programme`}
                      className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                      label={'Uraian Singkat Terkait Isi Dokumen'}
                      placeholder={'Uraian Singkat'}
                      textArea={true}
                      required={true}
                      disabled={isReadonly}
                    />
                  </div>
                </div>
              </div>
              {index !== 0 && !isReadonly && (
                <button
                  className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                  onClick={() => removePrograms(index)}
                >
                  <RiDeleteBin6Line />
                </button>
              )}
            </div>
          </CardForm>
        ))}
      <div className="border-b" />
      {
        isReadonly ? null : (
          <div className="flex flex-row-reverse my-5 mx-4 ">
            <Button
              className="p-4 border rounded-lg"
              label={
                <div className="flex items-center gap-2 font-[14px]">
                  <RiAddLine />
                  Tambah Program Lain
                </div>
              }
              onClick={() => {
                appendPrograms({
                  name: '',
                  desc: '',
                  program_relevance: '',
                  year: '',
                  execution_duration: '',
                  donor_name: '',
                  contract_value: '',
                  document_contract: '',
                  is_audit: '',
                  auditor: '',
                  result_audit: '',
                  document_audit: '',
                  report_programme: '',
                  desc_report_programme: '',
                });
              }}
            />
          </div>
        )
      }
    </CardForm>
  );
};

export default PengalamanPengelolaanProgram;
