import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { BottomFormAction, InputFormRadio, Spinner } from 'components';
import AlertBlankData, { AlertWarning } from 'components/molecules/Alert';
import QuestionBadge from 'components/molecules/Badge/QuestionBadge';
import CardForm from 'components/molecules/CardForm';
import React, { Fragment, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  getDetailSafeguards,
  postSafeguardsStep4,
} from 'services/danaProgram/callForProposalService';
import { getListRiskClassification } from 'services/masterData/riskClassiification';
import * as yup from 'yup';
import _ from 'lodash';
import { useNavigate } from 'react-router';

const validationSchema = yup.object({
  risk_classification: yup.array().of(yup.string().required('Wajib diisi')),
});

const defaultValues = {
  risk_classification: [],
};

const RiskClassification = ({ onNext, onBack, type }) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const navigate = useNavigate();
  const { id, editable } = useSelector(
    (state) => state?.callForProposalData?.data
  );

  const { data: dataDetail, isLoading: isLoadingDetail } = useQuery({
    queryKey: ['get-detail-safeguard-4'],
    queryFn: async () => {
      const res = await getDetailSafeguards({ proposal_id: id });
      return res?.data?.data;
    },
  });

  const { data: dataRiskClassification = [], isLoadingGetRisk } = useQuery({
    queryKey: ['get-list-risk-classification'],
    queryFn: async () => {
      const res = await getListRiskClassification();
      return res?.data?.data;
    },
  });

  useEffect(() => {
    if (dataDetail?.risk_classification) {
      methods.reset({
        risk_classification: dataDetail?.risk_classification.map(
          (el) => el?.answer_id
        ),
      });
    } else if (dataRiskClassification.length > 0) {
      methods.reset({
        risk_classification: dataRiskClassification,
      });
    }
  }, [dataDetail, dataRiskClassification]);

  const findLevelOfRisk = (id, index) => {
    let tempObj = _.reverse([...dataRiskClassification])[index];

    return !_.isEmpty(
      tempObj.klasifikasiTingkatRisikoMenengah.find((x) => x?.id == id)
    )
      ? 2
      : !_.isEmpty(
          tempObj.klasifikasiTingkatRisikoRendah.find((x) => x?.id == id)
        )
      ? 1
      : !_.isEmpty(
          tempObj.klasifikasiTingkatRisikoTinggi.find((x) => x?.id == id)
        )
      ? 3
      : 0;
  };

  const onSubmit = (data) => {
    let risk_classification = _.reverse([...dataRiskClassification]).map(
      (item, i) => {
        let objAnswer = _.reverse([
          ...(item?.klasifikasiTingkatRisikoMenengah || []),
          ...(item?.klasifikasiTingkatRisikoRendah || []),
          ...(item?.klasifikasiTingkatRisikoTinggi || []),
        ]).find((el) => el?.id == data.risk_classification[i]);

        return {
          question: item?.nama,
          classification_id: item?.id,
          answer_id: data.risk_classification[i],
          answer: objAnswer?.value,
          level_of_risk: findLevelOfRisk(data.risk_classification[i], i),
        };
      }
    );

    let payload = {
      id: dataDetail?._id,
      risk_classification: risk_classification,
    };

    submitStep4.mutate(payload);
  };

  const submitStep4 = useMutation({
    mutationKey: ['post-step-4-safeguard'],
    mutationFn: async (payload) => {
      const res = await postSafeguardsStep4(payload);
      return res;
    },
    onSuccess: (res) => {
      onNext(type);
    },
  });

  return (
    <Fragment>
      {isLoadingDetail || isLoadingGetRisk ? (
        <div className="w-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <AlertWarning
            children={
              <div>
                <p>Lengkapi Data</p>
                <small className="font-normal text-slate-700">
                  Silahkan isi semua data dibawah ini untuk bisa melanjutkan
                  pengisian ke tahap selanjutnya
                </small>
              </div>
            }
          />
          <FormProvider {...methods}>
            <CardForm label="klasifikasi tingkat resiko">
              {_.reverse([...dataRiskClassification]).map((item, i) => (
                <>
                  <QuestionBadge text={item?.nama} />
                  <InputFormRadio
                    directionColumn
                    disabled={!editable}
                    controllerName={`risk_classification.${i}`}
                    values={_.reverse([
                      ...(item?.klasifikasiTingkatRisikoMenengah || []),
                      ...(item?.klasifikasiTingkatRisikoRendah || []),
                      ...(item?.klasifikasiTingkatRisikoTinggi || []),
                    ]).map((val, i) => ({
                      label: val?.value,
                      value: val?.id,
                    }))}
                  />
                </>
              ))}
            </CardForm>
            <BottomFormAction
              isLoading={submitStep4.isLoading}
              lastStep={false}
              disableDrafButton={false}
              draftLabel={'Kembali Ke Proposal'}
              drafButtonAction={() => {
                navigate(`/proposal/call-for-proposal/${id}`);
              }}
              backButtonAction={() => onBack()}
              submitActionButton={() => {
                methods.handleSubmit(onSubmit)();
              }}
            />
          </FormProvider>
        </>
      )}
    </Fragment>
  );
};

export default RiskClassification;
