import { Button, InputForm } from "components";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";

const CurrentAssetOtherAsset = ({
  nestIndex = 0,
  sumCurrentAssetTotal = () => { },
  convertMoney = () => { },
  setValue = () => { },
  ...props
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const isReadonly = searchParams.get("readonly") === "true";
  const { watch, getValue, getValues, control } = useFormContext();
  const {
    fields: financialReportCurrentAssetOtherAssetFields,
    append: appendFinancialReportCurrentAssetOtherAsset,
    remove: removeFinancialReportCurrentAssetOtherAsset,
  } = useFieldArray({
    control,
    name: `financial_report.${nestIndex}.current_asset.other_asset`,
  });

  return (
    <>
      {financialReportCurrentAssetOtherAssetFields.length > 0
        ? financialReportCurrentAssetOtherAssetFields.map((data, index) => (
          <div className="flex gap-4 items-end  pt-2" key={data.id}>
            <div className="flex-1 pt-2">
              <InputForm
                // controllerName={`otherCurrentAsset.${index}.name`}
                controllerName={`financial_report.${nestIndex}.current_asset.other_asset.${index}.name`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={"Nama Aktiva Lainnya"}
                placeholder={"Aktiva"}
                disabled={isReadonly}
              />
            </div>
            <div className="flex-1 pt-2">
              <InputForm
                // controllerName={`otherCurrentAsset.${index}.value`}
                controllerName={`financial_report.${nestIndex}.current_asset.other_asset.${index}.value`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={"Nilai"}
                placeholder={"0"}
                // defaultValue={
                //   otherCurrentAssetCustom[index]?.value
                // }
                key={data.id}
                showPrefix={true}
                prefix={"Rp. "}
                disabled={isReadonly}
                onChangeInput={(e) => {
                  setValue(
                    `financial_report.${nestIndex}.current_asset.other_asset.${index}.value`,
                    convertMoney(e.target.value)
                  );
                  sumCurrentAssetTotal(nestIndex);
                }}
              />
            </div>
            {
              isReadonly ? null : (
                <button
                  className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                  onClick={() =>
                    removeFinancialReportCurrentAssetOtherAsset(index)
                  }
                >
                  <RiDeleteBin6Line />
                </button>
              )
            }
          </div>
        ))
        : ""}
      {
        isReadonly ? null : (
          <div className="border-b" />
        )
      }
      {
        isReadonly ? null : (
          <div className="flex flex-row-reverse my-5 mx-4 ">
            <Button
              className="p-4 border rounded-lg"
              label={
                <div className="flex items-center gap-2 font-[14px]">
                  <RiAddLine />
                  Tambah Aktiva Lancar Lain
                </div>
              }
              onClick={() => {
                // const prevArr = [...getValues("otherCurrentAsset")];
                // prevArr.push({
                //   name: "",
                //   value: "",
                // });
                // setOtherCurrentAssetCustom(prevArr);
                appendFinancialReportCurrentAssetOtherAsset({
                  name: "",
                  value: "",
                });
              }}
            />
          </div>
        )
      }
    </>
  );
};

export default CurrentAssetOtherAsset;
